<template>
  <div id="compoundPopBox">
    <div id="compoundPop" v-show="compoundPopShow">
      <img
        class="closeIcon"
        style="pointer-events: auto"
        @click="closeHandle"
        src="../../../imgs/compound/close.png"
      />
      <div>
        <img
          class="title"
          src="https://activity-resource.321mh.com/nft/activity_cartoon_competition/activity_images1656332787669.jpg"
        />
      </div>

      <div class="compound">
        <div style="position: relative" :class="flag ? 'trunActive' : ''">
          <img class="trun" src="../../../imgs/compound/trunAround.png" alt="" srcset="" />
          <img
            class="trun trunIcon"
            :class="open ? 'reduceActive' : ''"
            src="https://activity-resource.321mh.com/nft/hecheng/wuzhui/wuzhui_he.png"
            alt=""
            srcset=""
          />
          <!-- <div class="positionNum">{{ addnum }}</div> -->
        </div>

        <div class="flyGif">
          <img class="trun" src="../../../imgs/compound/fly.gif" alt="" srcset="" />
        </div>

        <div class="card" :class="open ? 'cardActive' : ''">
          <img
            src="https://activity-resource.321mh.com/nft/hecheng/wuzhui/wuzhui_zi.png"
            alt=""
            srcset=""
          />
        </div>
      </div>

      <div style="font-size: 16px">合成{{ merge_number }}份</div>

      <div class="btnBox" @click="compoundHandle(null)">
        <img src="../../../imgs/compound/btnBg.png" />
        <div class="confirmBtn">确定合成</div>
      </div>
    </div>

    <van-popup class="vantPop" v-model:show="show" @close="$emit('close')">
      <div>
        <div class="successImg">
          <img
            src="https://activity-resource.321mh.com/nft/activity_cartoon_competition/activity_images1656397513596.jpg"
          />
        </div>
        <div class="bigCard" :class="show ? 'bigCardActive' : ''">
          <img
            class="bg"
            src="https://activity-resource.321mh.com/nft/hecheng/wuzhui/wuzhui_collection.png"
          />

          <div class="num" v-if="cardInfo[0].serial_num && cardInfo[0].serial_num > 0">
            编号：{{ cardInfo[0].token_id + '-' + cardInfo[0].serial_num }}
          </div>

          <div class="cardPicBox">
            <div class="cardPic">
              <img class="pic" :src="cardInfo[0].image_icon" alt="" srcset="" />
              <img class="levelIcon" :src="levelsChange(cardInfo[0].levels)" alt="" />
            </div>
          </div>
        </div>
        <div class="btnBox" @click="toCollections(cardInfo[0].user_collection_id)">
          <img src="../../../imgs/compound/btnBg.png" />
          <div class="confirmBtn">查看藏品</div>
        </div>
      </div>
    </van-popup>

    <van-popup class="vantPop listPopBox" v-model:show="listPopShow" closeable>
      <div class="listPop">
        <div class="listPopTitle">恭喜获得</div>

        <div class="list">
          <div class="itemBox" v-for="(item, index) in cardInfo" :key="index">
            <div class="item">
              <div class="itemLeft">
                <img class="" :src="item.image_icon" alt="" srcset="" />
                <div style="margin: 0 6px">x</div>
                <div class="point">1</div>
              </div>
              <div class="goBtn" @click="toCollections(item.user_collection_id)">去查看</div>
            </div>
            <div style="margin-top: 4px">{{ item.items_name }}</div>
          </div>
        </div>
      </div>
    </van-popup>

    <van-popup
      class="vantPop"
      v-model:show="stockTip"
      :close-on-click-overlay="false"
      close-icon-position="top-right"
    >
      <div class="popup">
        <div class="content">
          <div class="popTitle">库存不足</div>
          <div class="desc">乌骓库存不足，当前最多可合成{{ left_cnt }}份，是否继续合成？</div>
        </div>
        <div class="btn">
          <div @click="cancelMerge">取消</div>
          <div @click="confirmMerge">合成</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { reactive, toRefs, inject } from 'vue';
import { wuzhuimergeitem } from '@/service/compound';
import { useRouter } from 'vue-router';
import popup from '@/components/popup/index.vue';

export default {
  props: {
    compoundPopShow: {
      type: Boolean,
      default: false
    },
    effect_img: {
      type: String,
      default: ''
    },
    addnum: {
      type: Number,
      default: 0
    },
    merge_number: {
      type: Number,
      default: 0
    }
  },
  components: {
    popup
  },

  setup(props, ctx) {
    const router = useRouter();
    const toastFn = inject('$toast');
    const state = reactive({
      step: 1,
      show: false,
      flag: false, //开始合成（请求接口，开始加载第一个动画trunActive）
      open: false, //请求接口获取合成的图片过程（加载第二个动画）reduceActive/cardActive
      popShow: true,

      cardInfo: {},
      popType: 1,
      cardData: {},
      compoundFlag: true,
      stockTip: false,
      listPopShow: false, // 批量合成弹窗
      left_cnt: null
    });

    // 确认合成
    const compoundHandle = (left_cnt = null) => {
      console.log('4', left_cnt);
      if (state.compoundFlag) {
        state.flag = true;
        state.compoundFlag = false;

        console.log('114324', left_cnt);

        let number = props.merge_number;
        if (left_cnt) {
          number = left_cnt;
        }
        setTimeout(() => {
          wuzhuimergeitem({
            number: number
          }).then(res => {
            console.log(res);
            if (res.status == 0) {
              state.cardInfo = res.data;
              state.open = true;

              if (state.cardInfo.length > 1) {
                state.listPopShow = true;
              } else {
                state.show = true;
              }

              setTimeout(() => {
                state.popShow = false;
                ctx.emit('close');
                state.open = false;
                state.flag = false;
                state.compoundFlag = true;
              }, 1000);
            } else if (res.status == 8) {
              state.left_cnt = res.data.left_cnt;
              if (state.left_cnt) {
                state.stockTip = true;
              } else {
                state.compoundFlag = true;
                state.flag = false;
                ctx.emit('close');
                toastFn({ message: '库存为0啦～', duration: 3000 });
              }
            } else {
              state.compoundFlag = true;
              state.flag = false;
              ctx.emit('close');
              toastFn({ message: res.message, duration: 3000 });
            }
          });
        }, 1000);
      } else {
        toastFn({ message: '正在合成' });
      }
    };

    // 查看藏品
    const toCollections = user_collection_id => {
      // router.push({ path: '/collections' });
      router.push({
        path: '/collectionsDetail',
        query: {
          id: user_collection_id
        }
      });
    };

    const levelsChange = level => {
      let src = '';
      //级别 1 普通 icon_label_ordinary，2 稀有 icon_label_rare，3 史诗 icon_label_epic，4 传说 icon_label_legend
      switch (level) {
        case 1:
          src = require('../../../imgs/comm/icon_label_ordinary.png');
          break;
        case 2:
          src = require('../../../imgs/comm/icon_label_rare.png');
          break;
        case 3:
          src = require('../../../imgs/comm/icon_label_epic.png');
          break;
        case 4:
          src = require('../../../imgs/comm/icon_label_legend.png');
          break;
        case 6:
          src = require('../../../imgs/comm/icon_label_cs.png');
          break;
        case 5:
          src = require('../../../imgs/comm/icon_label_sh.png');
          break;
      }
      return src;
    };

    const closeHandle = () => {
      if (state.compoundFlag) {
        ctx.emit('close');
      } else {
        toastFn({ message: '正在合成' });
      }
    };

    const confirmMerge = () => {
      state.stockTip = false;
      state.compoundFlag = true;
      state.flag = false;
      compoundHandle(state.left_cnt);
    };

    const cancelMerge = () => {
      state.compoundFlag = true;
      state.flag = false;
      state.stockTip = false;
      state.compoundPopShow = false;
      ctx.emit('close');
    };

    return {
      ...toRefs(state),
      compoundHandle,
      levelsChange,
      toCollections,
      closeHandle,
      confirmMerge,
      cancelMerge
    };
  }
};
</script>

<style lang="scss" scoped>
#compoundPop {
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #202232;
  // height: 100%;
  text-align: center;
}

.pointPop {
  position: relative;
  width: 300px;
  background: #2f293f;
  border-radius: 8px 8px 8px 8px;
  text-align: center;
  font-weight: 500;
  color: #ffffff;
  padding: 16px 0 20px 0;

  .closePoint {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px;
  }

  .topText {
    font-size: 18px;
    padding: 0 0 20px 0;
    margin: 0;
  }

  .img {
    font-size: 0;
    img {
      width: 48px;
    }
  }

  .number {
    font-size: 16px;
    margin: 9px 0 35px 0;
  }

  .getBtn {
    font-size: 16px;
    margin: 0 auto;
    width: 166px;
    height: 44px;
    line-height: 44px;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid rgba(255, 255, 255, 0.7);
  }
}
.closeIcon {
  position: absolute;
  z-index: 5000;
  width: 16px;
  height: 16px;
  padding: 20px;
  right: 0;
  top: 0;
}
.title {
  // width: 100%;
  width: 269px;
  margin-top: 30px;
  margin-bottom: 25px;
}

.compound {
  width: 100%;
  position: relative;
  // height: 388px;

  .flyGif {
    width: 100%;
    // height: 388px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .card {
    width: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    img {
      width: 100%;
    }
  }

  .trunAround {
    position: absolute;
    left: 0;
    top: 0;
  }

  .trun {
    width: 100%;
  }

  .trunIcon {
    position: absolute;
    left: 0;
    top: 0;
  }

  .positionNum {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 18px;
    left: 53%;
    font-size: 12px;
    font-weight: 500;
    color: #ffffff;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: #2f293f;
  }

  .trunActive {
    animation: trun 2s linear infinite;
  }

  .reduceActive {
    animation: reduce 1s linear 1;
  }

  .cardActive {
    animation: big 1s linear 1;
  }
}

.btnBox {
  width: 164px;
  font-size: 0;
  margin: 0 auto;
  margin-top: 10px;
  position: relative;

  img {
    width: 100%;
  }

  .confirmBtn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    font-size: 20px;
    font-weight: 600;
    color: #20322c;
  }
}

.successImg {
  width: 100%;
  margin-bottom: 10px;
  img {
    width: 100%;
  }
}

.bigCard {
  text-align: center;
  width: 292px;
  position: relative;
  .bg {
    width: 100%;
  }

  .num {
    position: absolute;
    width: 100%;
    top: 13px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.5);
  }

  .cardPicBox {
    position: absolute;
    width: 234px;
    top: 76px;
    left: 50%;
    transform: translateX(-50%);

    .cardPic {
      position: relative;

      .pic {
        width: 100%;
      }

      .levelIcon {
        position: absolute;
        width: 60px;
        bottom: 21px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

.bigCardActive {
  animation: bigCardAnimate 0.5s linear 1;
}

.listPop {
  width: 300px;
  max-height: 400px;
  background: #2f293f;
  border-radius: 8px 8px 8px 8px;
  padding: 16px 0;

  .listPopTitle {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
    line-height: 22px;
  }

  .list {
    padding: 20px 21px;
    font-size: 12px;
    font-weight: 400;
    color: #ffffff;
    line-height: 16px;
  }

  .itemBox {
    margin-bottom: 16px;
  }

  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .itemLeft {
      display: flex;
      align-items: center;
    }

    img {
      width: 56px;
      height: 56px;
      object-fit: cover;
    }

    .point {
      font-size: 16px;
      font-weight: 500;
      color: #3cdbe8;
    }

    .goBtn {
      padding: 4px 14px;
      border-radius: 29px 29px 29px 29px;
      border: 1px solid rgba(255, 255, 255, 0.7);
    }
  }
}
.popup {
  width: 300px;
  background: #2f293f;
  border-radius: 8px 8px 8px 8px;
  text-align: center;
  color: #ffffff;
  font-size: 16px;

  .content {
    padding: 16px 20px 20px 20px;

    .popTitle {
      font-size: 18px;
      font-weight: 500;
    }
    .desc {
      font-weight: 400;
      margin-top: 20px;
    }
  }
  .btn {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #494359;

    div {
      flex: 1;
      margin: 15px 0;
    }

    div:nth-child(2) {
      border-left: 1px solid #494359;
    }
  }
}

@keyframes trun {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes reduce {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.2);
  }
}

@keyframes big {
  from {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  to {
    transform: translate(-50%, -50%) scale(2.5);
    opacity: 0;
  }
}

@keyframes bigCardAnimate {
  from {
    opacity: 0;
    transform: scale(0.3);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
</style>

<style lang="scss">
#compoundPopBox {
  .listPopBox {
    background: #2f293f !important;
    border-radius: 8px 8px 8px 8px !important;
  }
  .vantPop {
    // background: transparent !important;
  }
}
</style>
